import apiCall from '../../index'

const apiService = '/api-erpunigas/api/v1/facturacion'
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const pertiodo = params.periodo === undefined ? '' : `&periodo=${params.periodo}`
    const query = `/lista_ventas?almacen=${params.almacen}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}&filtro_venta=${params.filtro_venta}${pertiodo}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  parameters() {
    const query = '/parametros_venta'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  reportesParameters() {
    const query = '/parametros_reporte'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  reportesContable(params) {
    const query = `/reporte_contable?periodo=${params.periodo}&tipo=${params.tipo}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getSeries(params) {
    console.log({ params })
    const idTrabajador = params.trabajador_id === undefined ? '&trabajador_id=0' : `&trabajador_id=${params.trabajador_id}`
    const query = `/list_series?tipo_documento_id=${params.tipo_documento_id}${idTrabajador}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getNroComprobante(params) {
    const query = `/ultimo_correlativo?tipo_documento=${params.tipo_documento_id}&serie_id=${params.serie_id}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getSearchDocument(params) {
    const query = `/consulta_cliente?nrodocumento=${params.documento}&tipo_documento=${params.tipo_documento}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getSearchProducto() {
    const query = '/producto?limit=-1&page=-1&tipo=grilla'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findById(params) {
    const query = `/${params.id}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  save(model) {
    const query = '/venta'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  saveGuia(model) {
    const query = '/guia_remision'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  consulta_ose(model) {
    const query = `/consulta_comprobante?nro_comprobante=${model.nro_comprobante}&serie=${model.serie}&tipo_documento=${model.tipo_documento}&venta_id=${model.venta_id}&guia_remision_id=${model.guia_remision_id}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(model) {
    const query = `/venta?nro_comprobante=${model.nro_comprobante}&serie=${model.serie}&tipo_documento=${model.tipo_documento}&venta_id=${model.venta_id}`
    return new Promise((resolve, reject) => {
      apiCall
        .delete(`${apiService}${query}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findAllGuia(params) {
    const query = `/lista_guias?almacen=${params.almacen}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}&filtro_venta=${params.filtro_venta}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findAllCompra(params) {
    const query = `/lista_compras?limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  saveCompra(model) {
    const query = '/compra'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  deleteCompra(model) {
    const query = `/compra?compra_id=${model.compra_id}`
    return new Promise((resolve, reject) => {
      apiCall
        .delete(`${apiService}${query}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  saveNotaCredito(params) {
    const query = `/nota_credito?nro_comprobante=${params.nro_comprobante}&serie=${params.serie}&tipo_documento=${params.tipo_documento}&venta_id=${params.venta_id}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  saveSerie(model) {
    const query = '/nueva_serie'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findAllTipoDocumento(params) {
    console.log(params)
    const query = '/list_tipo_documentos'
    // `/list_tipo_documentos?limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
