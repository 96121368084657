// import actions
import flotarAction from './actions/flota'
import tanqueEstacionarioAction from './actions/tanque_estacionario'
import abastecimientoAction from './actions/abastecimiento'
import trasegadoAction from './actions/trasegado'
// import getters

// import mutations

// import states

const actions = { ...flotarAction, ...tanqueEstacionarioAction, ...abastecimientoAction, ...trasegadoAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
