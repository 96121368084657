export default [
  {
    path: '/app/catalogo/marca',
    name: 'rt-catalogo-marca',
    component: () => import('@/views/erp/catalogo/marca/MarcaList.vue'),
    meta: {
      resource: 'rt-catalogo-marca',
      pageTitle: 'Marca',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Marca',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/catalogo/presentacion',
    name: 'rt-catalogo-presentacion',
    component: () => import('@/views/erp/catalogo/presentacion/PresentacionList.vue'),
    meta: {
      resource: 'rt-catalogo-presentacion',
      pageTitle: 'Presentación',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Presentación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/catalogo/tipo_flota',
    name: 'rt-catalogo-tipoflota',
    component: () => import('@/views/erp/catalogo/tipo_flota/TipoFlotaList.vue'),
    meta: {
      resource: 'rt-catalogo-tipoflota',
      pageTitle: 'Tipo de Flota',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Tipo de Flota',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/catalogo/empresa',
    name: 'rt-catalogo-empresa',
    component: () => import('@/views/erp/catalogo/empresa/EmpresaList.vue'),
    meta: {
      resource: 'rt-catalogo-empresa',
      pageTitle: 'Empresa',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Empresa',
          active: true,
        },
      ],
    },
  },
]
