export default [
  {
    path: '/app/rrhh/trabajador',
    name: 'rt-rrhh-trabajadores',
    component: () => import('@/views/erp/rrhh/trabajador/TrabajadorList.vue'),
    meta: {
      resource: 'rt-rrhh-trabajadores',
      pageTitle: 'Trabajador',
      breadcrumb: [
        {
          text: 'Recursos Humanos',
        },
        {
          text: 'Trabajador',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/rrhh/trabajador/:id',
    name: 'rt-rrhh-trabajadores-detalle',
    component: () => import('@/views/erp/rrhh/trabajador/detalle/TrabajadorDetalle.vue'),
    meta: {
      resource: 'rt-rrhh-trabajadores-detalle',
      pageTitle: 'Detalle de Trabajador',
      breadcrumb: [
        {
          text: 'Recursos Humanos',
        },
        {
          text: 'Detalle de Trabajador',
          active: true,
        },
      ],
    },
  },
]
