export default [
  {
    path: '/app/inventario/flota',
    name: 'rt-inventario-flota',
    component: () => import('@/views/erp/inventario/flota/FlotaList.vue'),
    meta: {
      resource: 'rt-inventario-flota',
      pageTitle: 'Flota',
      breadcrumb: [
        {
          text: 'Inventario',
        },
        {
          text: 'Flota',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/produccion/abastecimiento-cisterna-primaria',
    name: 'rt-produccion-cisterna-primaria',
    component: () => import('@/views/erp/produccion/cisterna-primaria/CisternaPrimariaList.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/app/produccion/trasegado',
    name: 'rt-produccion-trasegado',
    component: () => import('@/views/erp/produccion/trasegado/TrasegadoList.vue'),
  },
]
